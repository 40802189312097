import type { FixedPrice } from '@generated/graphql'

import { AdditionalPropertiesToShowConstant } from './AdditionalPropertiesToShowConstant'
import type { ImageElementData } from './NewProductDetails'
import type { AdditionalProperty } from './typings'

export const getProductSpecifications = (
  additionalProperties: AdditionalProperty[]
) => {
  return (
    additionalProperties
      ?.filter((item) => AdditionalPropertiesToShowConstant.includes(item.name))
      //   eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .reduce((a: any, v: any) => {
        if (a[v.name]) {
          a[v.name].value = [a[v.name].value, v.value].join(', ')
        } else {
          a[v.name] = v
        }

        return a
      }, {})
  )
}

export const translateFrequencies = (name: string) => {
  let translation = ''

  const nameToArray = name.trimStart().split(' ')

  switch (nameToArray[1]) {
    case 'day':
      translation =
        nameToArray[0] === '1'
          ? name.replace('day', 'giorno')
          : name.replace('day', 'giorni')
      break

    case 'week':
      translation =
        nameToArray[0] === '1'
          ? name.replace('week', 'settimana')
          : name.replace('week', 'settimane')
      break

    case 'month':
      translation =
        nameToArray[0] === '1'
          ? name.replace('month', 'mese')
          : name.replace('month', 'mesi')
      break

    case 'year':
      translation =
        nameToArray[0] === '1'
          ? name.replace('year', 'anno')
          : name.replace('year', 'anni')
      break

    default:
      translation = 'day'
      break
  }

  return translation
}

export const getImgResources = (
  images: ImageElementData[],
  videos: Array<string | null> | null
) => {
  const formattedVideos: ImageElementData[] = []

  videos?.forEach((el: string | null) => {
    const obj = { url: el ?? '', alternateName: 'video' }

    formattedVideos.push(obj)
  })

  return [...images, ...formattedVideos]
}

export const getSliderPositionClass = (
  size: number,
  selectedImgIndex: number,
  position: number
) => {
  if (selectedImgIndex === position) {
    return 'active'
  }

  if (
    selectedImgIndex === position + 1 ||
    (selectedImgIndex === 0 && position === size - 1)
  ) {
    return 'prev'
  }

  if (
    selectedImgIndex === position - 1 ||
    (selectedImgIndex === size - 1 && position === 0)
  ) {
    return 'next'
  }

  return ''
}

export const getPriceInfo = (
  listPrice: number,
  lowPrice: number,
  unitMultiplier: number | null | undefined
) => {
  const verifiedPrice = listPrice > lowPrice ? lowPrice : listPrice

  const verifiedPriceToShow = unitMultiplier
    ? verifiedPrice * unitMultiplier
    : verifiedPrice

  const verifiedListPriceToShow = unitMultiplier
    ? listPrice * unitMultiplier
    : listPrice

  const discount = Math.round(
    (1 - verifiedPriceToShow / verifiedListPriceToShow) * 100
  )

  const verifiedUnitMultiplier = unitMultiplier ?? 1

  return {
    verifiedPriceToShow,
    verifiedListPriceToShow,
    discount,
    verifiedUnitMultiplier,
  }
}

export const orderFixedPrices = (
  prices: Array<FixedPrice | null> | null | undefined
) => {
  return prices?.sort((a: any, b: any) => {
    if (a?.minQuantity === null && b?.minQuantity === null) {
      return 0
    }

    if (a?.minQuantity === null) {
      return 1
    }

    if (b?.minQuantity === null) {
      return -1
    }

    return a?.minQuantity - b?.minQuantity
  })
}

export const processAdditionalProperties = (name: string) => {
  switch (name) {
    case 'Tipo di alimento':
      return 'Esigenze'

    case 'Età':
      return 'Lifestage'

    default:
      return name
  }
}

export const getFixedPricesToShow = (
  fixedPrices: Array<FixedPrice | null> | null | undefined,
  quantity: number,
  unitMultiplier: number,
  listPrice: number,
  isSubscription?: boolean
) => {
  let result = 0

  const matchingObject = fixedPrices?.find((object, index) => {
    const nextObject = fixedPrices[index + 1]

    return (
      object?.minQuantity != null &&
      quantity >= object?.minQuantity &&
      (!nextObject ||
        (nextObject?.minQuantity != null && quantity < nextObject?.minQuantity))
    )
  })

  // If a matching object is found, return its price
  if (matchingObject && matchingObject.value != null) {
    result = isSubscription
      ? matchingObject.value * unitMultiplier * 0.95
      : matchingObject.value * unitMultiplier
  }

  // If the quantity parameter is greater than the last object's quantity, return the last object's price
  if (
    fixedPrices != null &&
    fixedPrices[fixedPrices.length - 1] != null &&
    fixedPrices[fixedPrices.length - 1]?.minQuantity != null &&
    fixedPrices[fixedPrices.length - 1]?.value != null &&
    quantity > fixedPrices[fixedPrices.length - 1]?.minQuantity!
  ) {
    const price = fixedPrices[fixedPrices.length - 1]?.value! * unitMultiplier

    result = isSubscription ? price * 0.95 : price
  }

  return {
    price: result,
    discount: Math.round((1 - result / listPrice) * 100),
  }
}

export const blockOutsideScroll = (isOpened: boolean) => {
  const scrollBarWidth = window?.innerWidth - document?.body?.clientWidth

  isOpened
    ? (document?.body?.classList?.add('modal-show'),
      document.body.classList.add('no-scroll'),
      (document.body.style.paddingRight = `${scrollBarWidth}px`))
    : (document?.body?.classList?.remove('modal-show'),
      document.body.classList.remove('no-scroll'),
      (document.body.style.paddingRight = '0px'))
}

export const clearRadioInputList = (el: HTMLDivElement | null) => {
  el?.querySelectorAll('input[type="radio"]')?.forEach(
    (choice: any) => (choice.checked = false)
  )
}
