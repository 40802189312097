import { Card, CardActions, CardContent } from '@faststore/ui'
import { useEffect, useState } from 'react'
import { TrashIcon } from 'src/components/icons/TrashIcon'
import type { AdditionalProperty } from 'src/components/sections/NewPDP/typings'
import { translateFrequencies } from 'src/components/sections/NewPDP/utilities'
import Button from 'src/components/ui/Button'
import { Image } from 'src/components/ui/Image'
import Price from 'src/components/ui/Price'
import QuantitySelector from 'src/components/ui/QuantitySelector'
// import { useProductSpecifications } from 'src/hooks/useProductSpecifications'
import { useRemoveButton } from 'src/sdk/cart/useRemoveButton'
import { useUpdateQuantity } from 'src/sdk/cart/useUpdateQuantity'
import type { CartItem as ICartItem } from 'src/sdk/cart/validate'
import { useFormattedPrice } from 'src/sdk/product/useFormattedPrice'
import './cart-item.scss'
import CartItemLoyaltyPoints from './CartItemLoyaltyPoints'

interface Props {
  item: ICartItem
  isCartValidating: boolean
  setIsVTEXValidating: any
}

function CartItem({ item, isCartValidating, setIsVTEXValidating }: Props) {
  const { onClick: onClickRemove, ...btnProps } = useRemoveButton(
    item,
    setIsVTEXValidating
  )

  const itemSku = item?.itemOffered?.sku

  const itemVariantFiltered = item?.itemOffered?.isVariantOf?.hasVariant.filter(
    (i) => i.sku === itemSku
  )

  const itemPrice = itemVariantFiltered[0]?.offers?.lowPrice

  const { updateItemQuantity, error } = useUpdateQuantity(
    item,
    setIsVTEXValidating
  )

  const { listPrice, price } = item
  const totalPriceByItem = price * item.quantity
  const [hasStock, setHasStock] = useState(Boolean)
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)

  // const productSpecifications = useProductSpecifications(item.itemOffered.sku)

  const outOfStockCard = hasStock ? '' : 'outOfStockCard'

  const subscriptions = item?.itemOffered?.additionalProperty?.filter(
    (el: AdditionalProperty) =>
      el.valueReference === 'ATTACHMENT' &&
      el.name.includes('vtex.subscription')
  )

  const discount = hasStock
    ? Math.trunc(100 - (itemPrice * 100) / listPrice)
    : Math.trunc(100 - (price * 100) / listPrice)

  const isZeroOrLess = discount <= 0

  let isPrivateLabel: boolean
  const skuAttributes =
    item?.itemOffered?.isVariantOf?.additionalProperty?.find(
      (el) => el.name === 'Arcaplanet SKU Attributes'
    )?.value

  try {
    isPrivateLabel =
      JSON.parse(skuAttributes)[item?.itemOffered?.sku]?.private_label ===
      'true'
  } catch (e) {
    isPrivateLabel = false
  }

  const privateLabelMoltiplicator = isPrivateLabel ? 2 : 1
  const loyaltyPoints =
    subscriptions?.length > 0
      ? Math.trunc(totalPriceByItem * 0.95) * privateLabelMoltiplicator
      : Math.trunc(totalPriceByItem) * privateLabelMoltiplicator

  const sessionIds = JSON.parse(
    window.sessionStorage.getItem('bundleItemsIds') ?? '[]'
  )
  const isItemInBundle = sessionIds?.includes(item?.itemOffered?.sku)

  const toggleTooltip = () => {
    setIsTooltipVisible(!isTooltipVisible)
  }

  useEffect(() => {
    setHasStock(itemPrice > 0)
  }, [itemPrice])

  return (
    <Card
      className={`cart-item ${outOfStockCard}`}
      data-testid="cart-item"
      data-sku={item?.itemOffered?.sku}
      data-seller={item?.seller?.identifier}
    >
      <CardContent>
        {error && (
          <div className="error">
            <p>{error}</p>
          </div>
        )}
        <div className="top">
          <div className="itemImage__container" style={{ display: 'block' }}>
            <Image
              baseUrl={item.itemOffered.image?.[0].url}
              alt={item.itemOffered.image?.[0].alternateName}
              sourceWidth={360}
              aspectRatio={1}
              width={55}
              breakpoints={[50, 100, 150]}
              loading="eager"
              layout="constrained"
              backgroundColor="#f0f0f0"
              options={{
                fitIn: true,
              }}
              imgStyle={{ display: 'block' }}
              style={{ display: 'block' }}
            />
          </div>
          <div data-cart-item-summary>
            <div>
              <p className="text__body" data-cart-item-title>
                {item.itemOffered.isVariantOf.name}
              </p>
            </div>
            <div className="item-loyalty-points-wrapper">
              <CartItemLoyaltyPoints loyaltyPoints={loyaltyPoints} />
              {isItemInBundle ? (
                <div className="bundle-discount-badge-wrapper">
                  <div className="bundle-discount-badge">SCONTO KIT</div>
                  <div
                    onClick={toggleTooltip}
                    className="bundle-icon-container"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M7.99967 15.1668C4.04767 15.1668 0.833008 11.9522 0.833008 8.00016C0.833008 4.04816 4.04767 0.833496 7.99967 0.833496C11.9517 0.833496 15.1663 4.04816 15.1663 8.00016C15.1663 11.9522 11.9517 15.1668 7.99967 15.1668ZM7.99967 1.8335C4.59901 1.8335 1.83301 4.5995 1.83301 8.00016C1.83301 11.4008 4.59901 14.1668 7.99967 14.1668C11.4003 14.1668 14.1663 11.4008 14.1663 8.00016C14.1663 4.5995 11.4003 1.8335 7.99967 1.8335ZM8.49967 11.0002V7.9528C8.49967 7.6768 8.27567 7.4528 7.99967 7.4528C7.72367 7.4528 7.49967 7.6768 7.49967 7.9528V11.0002C7.49967 11.2762 7.72367 11.5002 7.99967 11.5002C8.27567 11.5002 8.49967 11.2762 8.49967 11.0002ZM8.67969 5.66683C8.67969 5.29883 8.38169 5.00016 8.01302 5.00016H8.00635C7.63835 5.00016 7.34294 5.29883 7.34294 5.66683C7.34294 6.03483 7.64502 6.3335 8.01302 6.3335C8.38102 6.3335 8.67969 6.03483 8.67969 5.66683Z"
                        fill="#6C6C6C"
                      />
                    </svg>
                    <div
                      className={`bundle-icon-tooltip ${
                        isTooltipVisible ? 'visible' : ''
                      }`}
                    >
                      Sconto vincolato all'acquisto di tutto il kit
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <span data-cart-item-prices>
              {/* {!isZeroOrLess && (
                <DiscountBadge
                  listPrice={listPrice}
                  spotPrice={hasStock ? itemPrice : price}
                  big
                />
              )} */}
              {!isZeroOrLess && (
                <Price
                  value={listPrice}
                  formatter={useFormattedPrice}
                  testId="list-price"
                  data-value={listPrice}
                  variant="installment"
                  classes="text__legend"
                  SRText="Original price:"
                />
              )}
              <Price
                value={hasStock ? itemPrice : price}
                formatter={useFormattedPrice}
                testId="price"
                data-value={hasStock ? itemPrice : price}
                variant="spot"
                classes="text__title-subsection"
                SRText="Price:"
              />
            </span>
          </div>
          <Button
            variant="tertiary"
            icon={
              <TrashIcon
                width={28}
                height={26}
                color="#14181F"
                viewBox="0 0 28 26"
              />
            }
            iconPosition="left"
            {...btnProps}
            className="top__icon"
            onClick={isCartValidating ? undefined : onClickRemove}
          />
        </div>

        {!hasStock && (
          <div className="outofStock__message-container">
            <p>
              Questo articolo non è al momento disponibile.
              <br />
              <strong className="strongText-out">
                Rimuovi questo articolo
              </strong>{' '}
              per procedere <br />
              <span>all’acquisto.</span>
            </p>
          </div>
        )}

        <div className="bottom">
          <CardActions>
            <QuantitySelector
              min={1}
              initial={item.quantity}
              // unitMultiplier={productSpecifications.unitMultiplier}
              unitMultiplier={
                item.itemOffered?.productSpecifications?.unitMultiplier
              }
              onChange={(quantity) => {
                updateItemQuantity(quantity)
              }}
              disabled={isCartValidating}
            />
          </CardActions>
          <Price
            value={totalPriceByItem}
            formatter={useFormattedPrice}
            testId="price"
            data-value={totalPriceByItem}
            variant="spot"
            classes="text__title-subsection"
            SRText="Price:"
          />
        </div>

        {subscriptions?.length > 0 && (
          <div className="subscription-tag">
            <span className="arca-ico-autoship subscription-icon-minicart">
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
              <span className="path4"></span>
            </span>
            <span className="subscription-tag-title">Ordine Periodico</span>
            <span className="subscription-tag-frequency">Frequenza:</span>
            <span className="subscription-tag-frequency-value">
              {translateFrequencies(
                subscriptions[0]?.value['vtex.subscription.key.frequency']
              )}
            </span>
          </div>
        )}
      </CardContent>
    </Card>
  )
}

export default CartItem
