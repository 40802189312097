import { ButtonIcon } from 'src/components/ui/Button'
import { useCart } from 'src/sdk/cart/useCart'
import { useCartToggleButton } from 'src/sdk/cart/useCartToggleButton'
import './cart-toggle.scss'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { ChartIcon } from 'src/components/icons/ChartIcon'

function CartToggle() {
  const btnProps = useCartToggleButton()
  const { totalUniqueItems, isValidating } = useCart()
  const { isMobile } = useBreakpoint()

  return (
    <div className="cartButtonContainer">
      <ButtonIcon
        data-fs-button-cart="true"
        aria-label={`Cart with ${btnProps['data-items']} items`}
        disabled={isValidating}
        icon={
          <ChartIcon
            width={isMobile ? 24 : 32}
            height={isMobile ? 24 : 32}
            viewBox="0 0 32 32"
            color="currentColor"
          />
        }
        {...btnProps}
      />
      {totalUniqueItems > 0 &&
        (isValidating ? (
          <span className="relative flex items-center justify-center w-[22px] h-[22px] px-1 py-0 rounded-[50%] animate-[spinner_1s_linear_infinite] border-[3px] border-solid border-black border-t-gray-600 right-1.5 bottom-[11px]" />
        ) : (
          <span className="cartTotalValue">{totalUniqueItems}</span>
        ))}
    </div>
  )
}

export default CartToggle
