import { useSession } from '@faststore/sdk'
import type { FC } from 'react'
import React, { createContext, useContext, useEffect, useState } from 'react'
import {
  DeliveryEstimateDate,
  DeliveryEstimateRange,
  FavouriteStore,
  FavouriteStorePickingTime,
  SkuAvailability,
} from 'src/components/sections/Store/typings'
import { getSessionFavouriteStore } from 'src/components/sections/Store/utilities'
import { useStoreAPI } from 'src/sdk/store/useStoreAPI'
import { useModal } from 'src/sdk/ui/modal/useModal'
import capitalizeFirstLetter from 'src/utils/stringUtils'

export type StoreContext = {
  favouriteStore: FavouriteStore | null
  setFavouriteStore: (a: FavouriteStore) => void
  openStoreModal: boolean
  handleOpenStoreModal: (a: boolean) => void
  selectedSku: string
  setSelectedSku: (a: string) => void
  productsAvailableInStore: Array<string>
  setProductsAvailableInStore: (a: Array<string>) => void
  deliveryEstimate: DeliveryEstimateDate | DeliveryEstimateRange | null
  setDeliveryEstimate: (
    a: DeliveryEstimateDate | DeliveryEstimateRange | null
  ) => void
  showSavedStoreToast: boolean
  setShowSavedStoreToast: (a: boolean) => void
  pickingTime: FavouriteStorePickingTime | null
  setPickingTime: (a: FavouriteStorePickingTime | null) => void
  showEmailInfoToast: boolean
  setShowEmailInfoToast: (a: boolean) => void
  showStoreData: boolean
  setShowStoreData: (a: boolean) => void
  products: Array<SkuAvailability>
  setProducts: (a: Array<SkuAvailability>) => void
  availableProduct: string
  setAvailableProduct: (a: string) => void
  openStoreInfo: boolean
  handleOpenStoreInfo: (a: boolean) => void
}

const StoreContext = createContext({} as StoreContext)
export const StoreProvider: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { person } = useSession()

  const [favouriteStore, setFavouriteStore] = useState<FavouriteStore | null>(
    null
  )
  const [pickingTime, setPickingTime] =
    useState<FavouriteStorePickingTime | null>(null)
  const [deliveryEstimate, setDeliveryEstimate] = useState<
    DeliveryEstimateDate | DeliveryEstimateRange | null
  >(null)

  const [showSavedStoreToast, setShowSavedStoreToast] = useState<boolean>(false)
  const [showEmailInfoToast, setShowEmailInfoToast] = useState<boolean>(false)
  const { openStoreModal, handleOpenStoreModal } = useModal()
  const [selectedSku, setSelectedSku] = useState<string>('')
  const [productsAvailableInStore, setProductsAvailableInStore] = useState<
    Array<string>
  >([])
  const [showStoreData, setShowStoreData] = useState<boolean>(false)
  const [products, setProducts] = useState<Array<SkuAvailability>>([])
  const [availableProduct, setAvailableProduct] = useState<string>('')
  const { openStoreInfo, handleOpenStoreInfo } = useModal()

  const {
    getFavouriteStore,
    savePickupPointDataInSession,
    setFavouriteStoreInSession,
    getPickupPointById,
  } = useStoreAPI()

  useEffect(() => {
    const favouriteStore = getSessionFavouriteStore()

    if (favouriteStore) {
      setFavouriteStore(favouriteStore)
      savePickupPointDataInSession(favouriteStore.id, favouriteStore.sellerId)
    } else {
      person?.email &&
        getFavouriteStore(person?.email).then(async (store: any) => {
          if (store) {
            const pickupPoint = await getPickupPointById(`AGRI${store.Id}`)
            const favouriteStore = {
              id: store?.Id,
              name: `Arcaplanet - ${capitalizeFirstLetter(
                store?.City?.toLowerCase()
              )} ${capitalizeFirstLetter(store?.Street?.toLowerCase())}`,
              postalCode: store?.PostalCode,
              sellerId: store?.SellerId,
              geoCoordinates: [
                pickupPoint?.address?.location?.longitude,
                pickupPoint?.address?.location?.latitude,
              ],
            }

            setFavouriteStore(favouriteStore)
            savePickupPointDataInSession(
              favouriteStore.id,
              favouriteStore.sellerId
            )
            setFavouriteStoreInSession(favouriteStore)
          }
        })
    }
  }, [person?.email])

  const value = {
    favouriteStore,
    setFavouriteStore,
    openStoreModal,
    handleOpenStoreModal,
    selectedSku,
    setSelectedSku,
    productsAvailableInStore,
    setProductsAvailableInStore,
    deliveryEstimate,
    setDeliveryEstimate,
    showSavedStoreToast,
    setShowSavedStoreToast,
    pickingTime,
    setPickingTime,
    showEmailInfoToast,
    setShowEmailInfoToast,
    setShowStoreData,
    showStoreData,
    products,
    setProducts,
    availableProduct,
    setAvailableProduct,
    openStoreInfo,
    handleOpenStoreInfo,
  }

  return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
}

export const useStoreContext = () => {
  return useContext(StoreContext)
}
